import React, { useState } from 'react';
//import { Container } from 'react-bootstrap';
import ListingCard from './ListingCard';
import BidModal from './BidModal';
import { useListings } from './ListingsContext';
import styles from './styles/Dashboard.module.css';

const Dashboard = () => {
  const { listings } = useListings();
  const [showBidModal, setShowBidModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);

  const handleBidClick = (listing) => {
    setSelectedListing(listing);
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
    setSelectedListing(null);
  };

  return (
    <div className={styles.container}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className={styles.title}>GPU Listings</h1>
      </div>

      <div className={styles.listingsWrapper}>
        {listings.length === 0 ? (
          <div className="text-center mt-4">
            <h3>No listings available</h3>
          </div>
        ) : (
          <div className={styles.listingsGrid}>
            {listings.map((listing) => (
              <div className={styles.cardWrapper} key={listing.id}>
                <ListingCard 
                  listing={listing}
                  onBidClick={handleBidClick}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <BidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={selectedListing}
      />
    </div>
  );
};

export default Dashboard;