// ListingsContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import mockData from '../data/listings.json';

const ListingsContext = createContext();

export const useListings = () => useContext(ListingsContext);

export const ListingsProvider = ({ children }) => {
  const [allListings, setAllListings] = useState([]); // Store all listings
  const [listings, setListings] = useState([]); // Store filtered listings

  useEffect(() => {
    // Initialize with mock data
    setAllListings(mockData.listings);
    setListings(mockData.listings);
  }, []);

  const updateListings = (newListings) => {
    setListings(newListings);
  };

  const updateListing = (updatedListing) => {
    const updatedAllListings = allListings.map(listing => 
      listing.id === updatedListing.id ? updatedListing : listing
    );
    setAllListings(updatedAllListings);
    setListings(prevListings => 
      prevListings.map(listing => 
        listing.id === updatedListing.id ? updatedListing : listing
      )
    );
  };

  return (
    <ListingsContext.Provider value={{ 
      listings, 
      allListings,
      updateListings, 
      updateListing 
    }}>
      {children}
    </ListingsContext.Provider>
  );
};

export default ListingsContext;