import React from 'react';
import { useListings } from './ListingsContext';
import { Link } from 'react-router-dom';
import styles from './styles/MyBiddings.module.css';

const MyBiddings = () => {
  const { listings } = useListings();
  const myBids = listings.filter(listing => listing.highest_bidder_username === 'You');

  const formatCurrency = (value) => {
    const numValue = Number(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>My Biddings</h1>
      
      {myBids.length === 0 ? (
        <div className={styles.emptyState}>
          <p>You haven't placed any bids yet.</p>
          <Link to="/dashboard" className={styles.browseButton}>
            Browse Listings
          </Link>
        </div>
      ) : (
        <div className={styles.biddingsGrid}>
          {myBids.map((listing) => (
            <div key={listing.id} className={styles.biddingCard}>
              <div className={styles.cardHeader}>
                <h3 className={styles.gpuType}>{listing.gpu_type}</h3>
                <span className={styles.serverName}>{listing.server_name}</span>
              </div>
              
              <div className={styles.bidInfo}>
                <div className={styles.priceRow}>
                  <span className={styles.label}>Your Bid:</span>
                  <span className={styles.value}>
                    ${formatCurrency(listing.current_highest_bid)}
                  </span>
                </div>
                <div className={styles.priceRow}>
                  <span className={styles.label}>Ask Price:</span>
                  <span className={styles.value}>
                    ${formatCurrency(listing.ask_price)}
                  </span>
                </div>
                <div className={styles.locationRow}>
                  <span className={styles.label}>Location:</span>
                  <span className={styles.value}>
                    {listing.state}, {listing.country}
                  </span>
                </div>
              </div>

              <div className={styles.cardFooter}>
                <Link 
                  to={`/detail/${listing.id}`} 
                  className={styles.viewDetailsButton}
                >
                  View Details
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyBiddings;