import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import BidModal from './BidModal';
import { useListings } from './ListingsContext';
import styles from './styles/Detail.module.css';

const DetailRow = ({ label, value }) => (
  <div className={styles.detailRow}>
    <div className={styles.label}>{label}:</div>
    <div className={styles.value}>{value || 'Not Provided'}</div>
  </div>
);

const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [showBidModal, setShowBidModal] = useState(false);
  const { listings, updateListing } = useListings();

  useEffect(() => {
    const currentListing = listings.find(l => l.id === parseInt(id));
    if (currentListing) {
      setListing(currentListing);
    }
  }, [id, listings]);

  const handleBidClick = () => {
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
  };

  if (!listing) {
    return <Container className={styles.container}>Loading...</Container>;
  }

  const formatCurrency = (value) => {
    const numValue = Number(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <Button 
          variant="light"
          className={styles.backButton}
          onClick={() => navigate('/dashboard')}
        >
          Back to Listings
        </Button>
      </div>

      <h2 className={styles.title}>{listing.gpu_type} Details</h2>
      
      <div className={styles.bidButtonContainer}>
        <Button 
          variant="primary"
          className={styles.bidButton}
          onClick={handleBidClick}
        >
          Place Bid
        </Button>
      </div>

      <div className={styles.detailsContainer}>
        <DetailRow label="Server Name" value={listing.server_name} />
        <DetailRow label="Location" value={`${listing.state}, ${listing.country}`} />
        <DetailRow label="Condition" value={listing.condition} />
        <DetailRow label="Warranty" value={listing.warranty ? `${listing.warranty} years` : 'Not Provided'} />
        <DetailRow label="Ask Price" value={`$${formatCurrency(listing.ask_price)}`} />
        <DetailRow 
          label="Current Highest Bid" 
          value={
            listing.current_highest_bid 
              ? `$${formatCurrency(listing.current_highest_bid)}`
              : 'No bids yet'
          } 
        />
        <DetailRow label="Number of Nodes" value={listing.number_of_nodes} />
        <DetailRow label="GPUs per Node" value={listing.number_of_gpus_per_node} />
        <DetailRow label="Minimum Order" value={listing.minimum_order_quantity} />
        <DetailRow label="Processor Type" value={listing.processor_type} />
        <DetailRow label="Memory" value={`${listing.memory} GB`} />
        <DetailRow label="Operating Systems" value={listing.operating_systems?.join(', ')} />
        <DetailRow label="Motherboard" value={listing.motherboard} />
        <DetailRow label="Chassis" value={listing.chassis} />
        <DetailRow label="Chassis Form Factor" value={listing.chassis_form_factor} />
        <DetailRow label="Dimensions" value={listing.dimensions?.join(' x ')} />
        <DetailRow label="Net Weight" value={listing.net_weight ? `${listing.net_weight} kg` : null} />
        <DetailRow label="Gross Weight" value={listing.gross_weight ? `${listing.gross_weight} kg` : null} />
        <DetailRow label="System Cooling" value={listing.system_cooling} />
        <DetailRow label="Power Consumption" value={`${listing.power_consumption} W`} />
        <DetailRow label="Memory Type" value={listing.memory_type} />
        <DetailRow label="Configuration Type" value={listing.configuration_type} />
        <DetailRow label="CPU-GPU Interconnect" value={listing.cpu_gpu_interconnect} />
        <DetailRow label="GPU-GPU Interconnect" value={listing.gpu_gpu_interconnect} />
        <DetailRow label="Additional Notes" value={listing.additional_notes} />
      </div>

      <BidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={listing}
      />
    </Container>
  );
};

export default DetailPage;