import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useListings } from './ListingsContext';
import styles from './styles/BidModal.module.css';

const BidModal = ({ show, handleClose, listing }) => {
  const [bidAmount, setBidAmount] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [minBidAmount, setMinBidAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const { updateListing } = useListings();

  useEffect(() => {
    if (listing) {
      setMinBidAmount(listing.ask_price / 2);
      setBidAmount('');
      setIsChecked(false);
    }
    setMessage({ type: '', content: '' });
  }, [listing, show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', content: '' });

    if (!listing) {
      setMessage({ type: 'danger', content: 'No listing selected' });
      return;
    }

    if (!isChecked) {
      setMessage({ type: 'danger', content: 'Please confirm the terms and conditions' });
      return;
    }

    const bidAmountFloat = parseFloat(bidAmount);
    if (listing.current_highest_bid) {
      if (bidAmountFloat <= listing.current_highest_bid) {
        setMessage({ 
          type: 'danger', 
          content: `Bid must be higher than the current highest bid ($${Number(listing.current_highest_bid).toFixed(2)})` 
        });
        return;
      }
    } else {
      if (bidAmountFloat < minBidAmount) {
        setMessage({ 
          type: 'danger', 
          content: `Bid must be at least $${minBidAmount.toFixed(2)}` 
        });
        return;
      }
    }

    // Simulate bid placement with local data
    const updatedListing = {
      ...listing,
      current_highest_bid: bidAmountFloat,
      highest_bidder_username: 'You' // Simplified for demo
    };

    updateListing(updatedListing);
    setMessage({ type: 'success', content: 'Bid placed successfully!' });

    setTimeout(() => {
      handleClose();
      setMessage({ type: '', content: '' });
      setIsChecked(false);
    }, 2000);
  };

  if (!listing) {
    return null;
  }

  const formatCurrency = (value) => {
    const numValue = Number(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  return (
    <Modal show={show} onHide={handleClose} className={styles.modal}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>Place a Bid</Modal.Title>
        <button className={styles.closeButton} onClick={handleClose}>×</button>
      </Modal.Header>
      
      <Modal.Body className={styles.modalBody}>
      <p className={styles.modalSubtitle}>
        You will obtain this hardware as long as your bid is over the current highest bid
      </p>
        <Form onSubmit={handleSubmit}>
          <div className={styles.infoRow}>
            <div className={styles.infoText}>
              Ask Price: ${formatCurrency(listing?.ask_price)}
            </div>
            {listing.current_highest_bid === 0 ? (
              <div className={styles.infoText}>
                Minimum Bid: ${formatCurrency(minBidAmount)}
              </div>
            ) : (
              <div className={styles.currentBid}>
                Current Highest Bid: ${formatCurrency(listing.current_highest_bid)}
              </div>
            )}
          </div>

          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Your Bid Amount (USD)
            </Form.Label>
            <Form.Control
              className={styles.formControl}
              type="number"
              step="0.01"
              value={bidAmount}
              onChange={(e) => setBidAmount(e.target.value)}
              required
              min={listing.current_highest_bid > 0 ? (listing.current_highest_bid + 0.01) : minBidAmount}
            />
          </Form.Group>

          <Form.Group className={styles.checkboxGroup}>
          <Form.Check
            type="checkbox"
            id="terms-checkbox"
            className={styles.checkbox}
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            label="I confirm that I have read and agree to the terms 
            and conditions of this bid"
          />
          </Form.Group>

          {message.content && (
            <Alert className={`${styles.alert} ${styles[message.type]}`}>
              {message.content}
            </Alert>
          )}

          <Button className={styles.submitButton} type="submit">
            Place Bid
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BidModal;