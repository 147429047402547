import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.content}>
          {/* Left Section */}
          <div className={styles.section}>
            <div className={styles.brand}>
              <span className={styles.brandName}>FPX HPC Marketplace</span>
            </div>
            <p className={styles.description}>
              A next-generation platform for the rapidly growing high performance compute ecosystem.
            </p>
          </div>

          {/* Right Section */}
          <div className={styles.section}>
            {/* Empty right section to maintain layout */}
          </div>
        </div>

        <div className={styles.copyright}>
          © 2024 FPX HPC Marketplace
        </div>
      </div>
    </footer>
  );
};

export default Footer;