// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useListings } from './ListingsContext';
import styles from './styles/Navbar.module.css';

const NavigationBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { allListings, updateListings } = useListings();

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    
    if (!newSearchTerm.trim()) {
      updateListings(allListings); // Show all listings when search is empty
      return;
    }

    const filteredListings = allListings.filter(listing => 
      listing.gpu_type.toLowerCase().includes(newSearchTerm.toLowerCase()) ||
      listing.server_name.toLowerCase().includes(newSearchTerm.toLowerCase()) ||
      listing.state.toLowerCase().includes(newSearchTerm.toLowerCase()) ||
      listing.country.toLowerCase().includes(newSearchTerm.toLowerCase())
    );
    updateListings(filteredListings);
  };

  return (
    <div className={styles.navWrapper}>
      <div className={styles.navbar}>
        <div className={styles.leftSection}>
          <Link to="/dashboard" className={styles.brand}>
            HPC-Marketplace
          </Link>
          <nav className={styles.nav}>
            <Link to="/" className={styles.navLink}>Home</Link>
            <Link to="/dashboard" className={styles.navLink}>Listings</Link>
            <Link to="/my-biddings" className={styles.navLink}>My Biddings</Link>
          </nav>
        </div>
        <div className={styles.searchSection}>
          <form onSubmit={(e) => e.preventDefault()} className={styles.searchForm}>
            <input
              type="search"
              placeholder="Search listings..."
              className={styles.searchInput}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className={styles.buttonGroup}>
              <button type="submit" className={styles.searchButton}>
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;