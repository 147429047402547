import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import DetailPage from './components/DetailPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ListingsProvider } from './components/ListingsContext';
import './App.css';
import backgroundImage from './components/assets/bg.jpg';
import MyBiddings from './components/MyBiddings';

function App() {
  return (
    <BrowserRouter>
      <ListingsProvider>
        <div className="App" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/dashboard"
              element={
                <>
                  <Navbar />
                  <Dashboard />
                  <Footer />
                </>
              }
            />
            <Route
              path="/detail/:id"
              element={
                <>
                  <Navbar />
                  <DetailPage />
                  <Footer />
                </>
              }
            />
            <Route
              path="/my-biddings"
              element={
                <>
                  <Navbar />
                  <MyBiddings />
                  <Footer />
                </>
              }
            />
          </Routes>
        </div>
      </ListingsProvider>
    </BrowserRouter>
  );
}

export default App;