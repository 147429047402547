import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/ListingCard.module.css';

const formatValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value.join(', ') : 'Not Provided';
  }
  if (typeof value === 'object' && value !== null) {
    if ('length' in value && 'width' in value && 'height' in value) {
      return `${value.length || 0} × ${value.width || 0} × ${value.height || 0} inches`;
    }
    return 'Not Provided';
  }
  return value || 'Not Provided';
};

const DetailRow = ({ label, value }) => (
  <div className={styles.detailRow}>
    <span className={styles.label}>{label}:</span>
    <span className={styles.value}>{value}</span>
  </div>
);

const ListingCard = ({ listing, onBidClick }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/detail/${listing.id}`);
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{listing.gpu_type}</h3>
        
        <div className={styles.details}>
          <DetailRow label="Server Name" value={listing.server_name} />
          <DetailRow label="Condition" value={listing.condition} />
          <DetailRow label="Chassis" value={listing.chassis} />
          <DetailRow label="Location" value={`${listing.state}, ${listing.country}`} />
        </div>

        <div className={styles.priceSection}>
          <div className={styles.price}>Ask Price: ${listing.ask_price}</div>
          <div className={styles.bids}>
            {listing.current_highest_bid ? 
              `Highest Bid: $${listing.current_highest_bid}` : 
              'No bids yet'
            }
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <button 
            onClick={() => onBidClick(listing)}
            className={`${styles.button} ${styles.primaryButton}`}
          >
            Place Bid
          </button>
          <button 
            onClick={handleViewDetails}
            className={styles.button}
          >
            Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;